import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import DateView from './DateView';
//import Paper from '@material-ui/core/Paper';
//import Grid from '@material-ui/core/Grid';
import { compose } from 'react-apollo';
//import gql from 'graphql-tag';
import StringField from './StringField';
import StringViewField from './StringViewField';
import Lodash from 'lodash';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import AccountIcon from '@material-ui/icons/AccountBox';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import LinearProgress from '@material-ui/core/LinearProgress';
import { /*parseNumber as parsePhoneNumber,*/ isValidNumber as isValidPhoneNumber/*, formatNumber, AsYouType */} from 'libphonenumber-js'

const evalidator = require("email-validator");

const styles = theme => ({
  grid_root: {
      flexGrow: 1,
  },
  toolbar: {
    //border: "1px solid green",
  },
  paper: {
    padding: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit,
  },
  accounticon: {
    marginRight: 20
  },
  grow: {
    flexGrow: 1,
  },
  desc: {
    padding: theme.spacing.unit
  },
  button: {
    margin: theme.spacing.unit
  }
});


class SaleEventBuyTicket extends React.Component {

  constructor(props) {
      super(props);
      this.state={
        ticket_order_id:null,
        customer: {
          name:null,
          surname:null,
          email:null,
          phone:null,
        },
     
        customer_err: {
          name:false,
          surname:false,
          email:false,
          phone:false,
        },

        dirty:false,
        valid:false,
        wait_pay:false,
      }
  } 

  handleDocChange(name,value){
    let { customer, customer_err } = this.state;
    customer[name]=value;
    customer_err[name]=!this.checkDocField(name,value);
    this.setState({
      customer:customer,
      customer_err:customer_err,
      dirty:true,
      valid:this.checkDoc(customer)
    });
  }

 // checkDocField(name,value) {
 //   if (["name","surname","email","phone"].includes(name)  && value===null) {
 //     return false;
 //   }
 //   return true;
 // }


  checkDocField(name,value) {
    // const {cost_mode} = this.props;
     switch(name) {
     case 'name':
     case 'surname':
         return (value!=null) && (value.length>0) && (value.length<100);    
     case 'email':
         return (value!=null) && evalidator.validate(value);
     case 'phone':
         return (value!=null) && isValidPhoneNumber(value,'CZ');
     default:
         return (true);
     }
 }

  getDocErr(doc) {
    const me = this;
    const doc_err = Lodash.reduce(Lodash.keys(doc),(acc,n)=>{
      var di = {};
      di[n]=!me.checkDocField(n,doc[n]);
      return Object.assign(acc,di);
    },{})
    return doc_err;
  }

  checkDoc(doc) {
    return Lodash.every(Lodash.values(this.getDocErr(doc)),(v)=>!v);
  }

  handleToPay() {
    this.setState({wait_pay:true});
    this.props.onBuy(result=>{  //false = problem
      if (result) {
        //?
      } else {
        this.setState({wait_pay:false})
      }
    });
  }

  handleBackToOrder() {
    this.props.onBack();
  } 

  renderWaitDialog() {
    const {wait_pay} = this.state;
    return (
      <Dialog open={wait_pay}>
        <DialogTitle>Probíhá přesměrování na platební bránu</DialogTitle>
        <LinearProgress />
      </Dialog>
    )
  }

  render() {
    const { classes,public_order_id,order} = this.props;
    const { customer, customer_err, valid,wait_pay} = this.state;

    const has_order = (public_order_id!=null) && (order!=null);
    const wait_dialog = this.renderWaitDialog();

    return (
      <React.Fragment>
        {wait_dialog}
        <Toolbar variant={"dense"}  className={classes.toolbar}>
          <AccountIcon  className={classes.accounticon} />
          <Typography className={classes.grow} variant="title" color="inherit"> 
              {has_order?"Kontrola a platba objednávky "+public_order_id:"Údaje pro doručení vstupenek"} 
          </Typography>
        </Toolbar>
        <Divider/>
            
          {has_order?(
            <React.Fragment>

              <Typography className={classes.desc} variant="subheading" color="inherit">
                 Vstupenky po zaplacení pošleme na uvedenou email adresu a kódy vstupenek pro jistotu i jako SMS. Jméno a přijmení potřebujeme znát pro případnou kontrolu platby či řešení problemů.
              </Typography>

              <StringViewField required width={300} id="name" label="Jméno" value={order.customer_name} />
              <StringViewField required width={300} id="surname" label="Přijmení" value={order.customer_surname}/>
              <StringViewField required width={300} id="phone" label="Telefon" value={order.customer_phone}  />
              <StringViewField required width={300} id="email" label="Email" value={order.customer_email}  />
            </React.Fragment>
          ):(
            <React.Fragment>

              <Typography className={classes.desc} variant="subheading" color="inherit">
                 Vstupenky po zaplacení pošleme na uvedenou email adresu a kódy vstupenek pro jistotu i jako SMS. Jméno a přijmení potřebujeme znát pro případnou kontrolu platby či řešení problemů.
              </Typography>

              <StringField required width={300} id="name" label="Jméno" value={customer.name} error={customer_err.name} onChange={(v)=>this.handleDocChange("name",v)} />
              <StringField required width={300} id="surname" label="Přijmení" value={customer.surname} error={customer_err.surname} onChange={(v)=>this.handleDocChange("surname",v)} />
              <StringField required width={300} id="phone" label="Telefon" value={customer.phone} error={customer_err.phone} onChange={(v)=>this.handleDocChange("phone",v)} />
              <StringField required width={300} id="email" label="Email" value={customer.email} error={customer_err.email} onChange={(v)=>this.handleDocChange("email",v)} />
            </React.Fragment>
          )}  

      
        <Toolbar variant={"dense"}  className={classes.toolbar}>
          {has_order?(
            <React.Fragment>
              <Typography className={classes.grow} variant="body2" color="inherit"> Pokud je vše v pořádku, stačí objedávku zaplatit kartou a vstupenky Vám pošleme. </Typography>
              <Button className={classes.button} variant="contained" disabled={wait_pay} onClick={()=>this.handleBackToOrder()}> zpět k objednávce </Button>
              <Button className={classes.button} variant="contained" disabled={wait_pay} onClick={()=>this.handleToPay()}> zaplatit kartou </Button>
            </React.Fragment>
          ):(
            <React.Fragment>
              <Typography className={classes.grow} variant="title" color="inherit"> &nbsp; </Typography>
              <Button className={classes.button} variant="contained" disabled={!valid} onClick={()=>this.props.onCreateOrder(this.state.customer)}> pokračovat k platbě </Button>
            </React.Fragment>
          )}
        </Toolbar>

      </React.Fragment>
    );
  }
}

SaleEventBuyTicket.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  public_order_id: PropTypes.string,
  order: PropTypes.object,
  onCreateOrder: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onBuy: PropTypes.func.isRequired
};
SaleEventBuyTicket.defaultProps = {
  public_order_id:null,
  order:null
};



export default compose(
  withStyles(styles),
  /*
  graphql(CREATE_EVENT_TICKET_BOOKING,{
    name: "createEventTicketBooking",
  }),
  graphql(DELETE_EVENT_TICKET_BOOKING,{
    name: "deleteEventTicketBooking",
  }),*/
)(SaleEventBuyTicket)
