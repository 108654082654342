import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SaleEventOrderDone from './SaleEventOrderDone';
import Paper from '@material-ui/core/Paper';



const styles = theme => ({
  root: {
  //   border: "1px solid red",
     flexGrow: 1,
  },
});





class TicketsAppOrderDone extends React.Component {

  constructor(props) {
    super(props);
  }
 
  render() {
    const { classes,event, order, paid, get_pdf_url,onRePay} = this.props;

    return (
      <Paper className={classes.root}>
        <SaleEventOrderDone event={event} order={order} paid={paid} onRePay={onRePay} get_pdf_url={get_pdf_url}/>
      </Paper>
    );
  }
}

TicketsAppOrderDone.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  order: PropTypes.object,
  paid: PropTypes.bool.isRequired,
  get_pdf_url: PropTypes.func.isRequired,
  onRePay: PropTypes.func.isRequired,
};
TicketsAppOrderDone.defaultProps = {
  
};


export default withStyles(styles)(TicketsAppOrderDone);