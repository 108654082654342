import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SaleEventOrderCartList from './SaleEventOrderCartList';
import Paper from '@material-ui/core/Paper';



const styles = theme => ({
  root: {
   //  border: "1px solid red",
     flexGrow: 1,
  },
});





class TicketsAppCartDone extends React.Component {

  constructor(props) {
    super(props);
    this.list = React.createRef();
  }
 
  render() {
    const { classes,event,order,paid } = this.props;

    return (
      <Paper className={classes.root}>
        <SaleEventOrderCartList innerRef={this.list} order_price={order.price} paid={paid} list={order.tickets} event_seats={event.seats} event_zones={event.zones}/>  
      </Paper>
    );
  }
}

TicketsAppCartDone.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  paid: PropTypes.bool.isRequired,
  dense: PropTypes.bool,
};
TicketsAppCartDone.defaultProps = {
  dense: false,
};


export default withStyles(styles)(TicketsAppCartDone);