import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TicketsAppCart from './TicketsAppCart';
import TicketsAppOrder from './TicketsAppOrder';
import TicketsAppChoose from './TicketsAppChoose';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router';

const styles = theme => ({
  grid_root: {
      flexGrow: 1,
     // border: "1px solid green",
  },
  paper: {
    padding: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

/*
event_seat_id: ID
event_seat_idx: Int
event_zone_id: ID!
event_zone_price_id: ID!
booking_ref: String!
*/

const CREATE_EVENT_TICKET_BOOKING_PUBLIC = gql`
mutation eventTicketCreateBooking($event_seat_id:ID,$event_seat_idx:Int,$event_zone_id:ID!,$event_zone_price_id:ID!,$booking_ref:String!) {
  eventTicketCreateBooking(event_zone_id:$event_zone_id,event_seat_id:$event_seat_id,event_seat_idx:$event_seat_idx,event_zone_price_id:$event_zone_price_id,booking_ref:$booking_ref) {
    ok
    error_code
    error_msg
  }
}
`;

const DELETE_EVENT_TICKET_BOOKING_PUBLIC = gql`
mutation eventTicketDeleteBooking($event_ticket_key:String!,$booking_ref:String!) {
  eventTicketDeleteBooking(event_ticket_key:$event_ticket_key,booking_ref:$booking_ref) {
    ok
    error_code
    error_msg
  }
}
`;


const CREATE_EVENT_TICKET_ORDER_PUBLIC = gql`
mutation eventTicketCreateOrder($event_id: ID!,$booking_ref: String!,$customer_name: ValidatedStringShort!,$customer_surname: ValidatedStringShort!,$customer_email: ValidatedStringShort!,$customer_phone: ValidatedStringShort!) {
  eventTicketCreateOrder(event_id:$event_id,booking_ref:$booking_ref,customer_name:$customer_name,customer_surname:$customer_surname,customer_email:$customer_email,customer_phone:$customer_phone) {
    ok
    error_code
    error_msg
    order_public_key
  }
}
`;
const BUY_EVENT_TICKET_ORDER_PUBLIC = gql`
mutation eventTicketBuyOrder($event_id: ID!,$booking_ref: String!,$order_public_key: String!) {
  eventTicketBuyOrder(event_id:$event_id,booking_ref:$booking_ref,public_order_key:$order_public_key) {
    ok
    error_code
    error_msg
    payment_url
  }
}
`;


class TicketsAppSale extends React.Component {

  constructor(props) {
      super(props);
      this.state={
    
      }
      this.cart = React.createRef();
  } 

  reload() {
    this.props.refetch();
  }

  handleCreateOrder(customer) {
    console.log("handleCreateOrder",customer);
    this.props.createEventTicketOrder({
      variables:{
        event_id:this.props.event.id,
        booking_ref:this.props.booking_ref,
        customer_name:customer.name,
        customer_surname:customer.surname,
        customer_email:customer.email,
        customer_phone:customer.phone
      }
    }).then(res=>{
      if (!res.data.eventTicketCreateOrder.ok) {
        alert(res.data.eventTicketCreateOrder.error_msg);
        this.reload();
      } else {
      //  alert("NEW ORDER ID IS:"+res.data.eventTicketCreateOrder.order_public_key)
        this.props.history.push('/tickets_spa/'+this.props.event.id+'/order/'+res.data.eventTicketCreateOrder.order_public_key);
      }
    }).catch(err=>{
      alert(err);
      this.reload();
    })
  }

  handleCreateTicket(ticket,cb) {
    console.log("handleCreateTicket",ticket);
    this.props.createEventTicketBooking({
      variables: {
        event_seat_id:ticket.seat?ticket.seat.id:null,
        event_seat_idx:ticket.seat?ticket.seat_idx:null,
        event_zone_id:ticket.zone.id,
        event_zone_price_id:ticket.price.id,
        booking_ref:this.props.booking_ref
      }
    }).then(res=>{
      if (!res.data.eventTicketCreateBooking.ok) {
        alert(res.data.eventTicketCreateBooking.error_msg);
      }
      cb(res.data.eventTicketCreateBooking);
      this.reload();
    }).catch(err=>{
      alert(err);
      cb({ok:false,error_msg:"interní chyba"});
      this.reload();
    })

   
  }

  

  deleteBooking(ticket) {
      this.props.deleteEventTicketBooking({
        variables: {
          event_ticket_key:ticket.ticket_key,
          booking_ref:this.props.booking_ref
        }
      }).then(res=>{
        if (!res.data.eventTicketDeleteBooking.ok) {
          alert(res.data.eventTicketDeleteBooking.error_msg);
        }
        this.reload();
      }).catch(err=>{
        alert(err);
        this.reload();
      })
  }

  handleNewTicket(ticket) {
    this.reload();
  }

 
  handlePrepOrder() {
    this.props.history.push('/tickets_spa/'+this.props.event.id+'/order');
  }
  handleBuy(cb) {
    const {public_order_id,booking_ref,event} =  this.props;
    this.props.buyEventTicketOrder({
      variables: {
        event_id:event.id,
        order_public_key: public_order_id,
        booking_ref:booking_ref
      }
    }).then(res=>{
      if (!res.data.eventTicketBuyOrder.ok) {
        alert(res.data.eventTicketBuyOrder.error_msg);
        cb(false);
        this.reload();
      } else {
        window.location.href = res.data.eventTicketBuyOrder.payment_url;
      }
    }).catch(err=>{
      alert(err);
      cb(false);
      this.reload();
      
    })
  
  }

  handleModify() {
    this.props.history.push('/tickets_spa/'+this.props.event.id);
  }

  render() {
    const { classes,event,public_order_id,my_tickets,my_order,as_order} = this.props;
    const has_order = (as_order && public_order_id && public_order_id.length>0);
    if (has_order && my_order==null) {
      return (
        <div className={classes.grid_root}>
          <Typography variant="title" color="inherit" >Objednávka {public_order_id} nebyla nalezena</Typography>
        </div>
      )
    }
    const tickets = has_order?my_order.tickets:my_tickets;
    return (
      <React.Fragment>
            <div className={classes.grid_root}>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={8}>
                {(as_order)?(
                  <TicketsAppOrder event={event} refetch={this.props.refetch} public_order_id={public_order_id} order={my_order} onCreateOrder={(c)=>this.handleCreateOrder(c)} onBack={()=>this.handlePrepOrder()} onBuy={(cb)=>this.handleBuy(cb)}/>
                ):(
                  <TicketsAppChoose event={event} onCreateNewTicket={(t,cb)=>this.handleCreateTicket(t,cb)}/>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                  <TicketsAppCart as_order={as_order} has_order={has_order} onBuy={()=>this.handlePrepOrder()} onModify={()=>this.handleModify()} onDelete={(t)=>this.deleteBooking(t)} innerRef={this.cart} event={event} booking_ref={this.props.booking_ref} my_tickets={tickets} />
              </Grid>
            </Grid>
      
            </div>
      </React.Fragment>
    );
  }
}

TicketsAppSale.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  createEventTicketBooking: PropTypes.func.isRequired,
  deleteEventTicketBooking: PropTypes.func.isRequired,
  createEventTicketOrder: PropTypes.func.isRequired,
  buyEventTicketOrder:  PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  public_order_id: PropTypes.string,
  my_tickets: PropTypes.array.isRequired,
  my_order: PropTypes.object,
  as_order: PropTypes.bool,
  booking_ref: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};
TicketsAppSale.defaultProps = {
  as_order:false,
};



export default withRouter(compose(
  withStyles(styles),
  graphql(CREATE_EVENT_TICKET_BOOKING_PUBLIC,{
    name: "createEventTicketBooking",
  }),
  graphql(DELETE_EVENT_TICKET_BOOKING_PUBLIC,{
    name: "deleteEventTicketBooking",
  }),
  graphql(CREATE_EVENT_TICKET_ORDER_PUBLIC,{
    name: "createEventTicketOrder",
  }),
  graphql(BUY_EVENT_TICKET_ORDER_PUBLIC,{
    name: "buyEventTicketOrder",
  }),

)(TicketsAppSale));
