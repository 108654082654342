import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Version from "./Version";
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MapIcon from '@material-ui/icons/Place';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import Button from '@material-ui/core/Button';
import { compose, graphql } from 'react-apollo';
import { setBookingRef } from './../actions'
import { connect } from 'react-redux'
import gql from 'graphql-tag';
import { withRouter } from 'react-router';
import { Route, Switch, Link , Redirect} from 'react-router-dom';
import { Query } from "react-apollo";
import CircularProgress from '@material-ui/core/CircularProgress';
import EventStrip from './EventStrip';
//import DebugObjectView from './Utils/DebugObjectView';
import TicketsAppSale from './TicketsAppSale';
import TicketsAppSaleDone from './TicketsAppSaleDone';
import Divider from '@material-ui/core/Divider'
//import Paper from '@material-ui/core/Paper'
const styles = theme => ({
  root: {
    //flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing.unit*12,
      marginRight: theme.spacing.unit*12,
    },
    marginTop: theme.spacing.unit*2,
    marginBottom: theme.spacing.unit,
    //backgroundColor: "white"
  },

  toplinks: {
    
  },

  topbanner: {
    marginTop:theme.spacing.unit,
    marginBottom:theme.spacing.unit
  },

  bottom_bar: {
    paddingTop:theme.spacing.unit,
    paddingBottom:theme.spacing.unit,
    paddingLeft:theme.spacing.unit,
    paddingRight:theme.spacing.unit,
    color: "white",
    backgroundColor: "black",
  },

  main_bar: {
    paddingTop:theme.spacing.unit,
    paddingBottom:theme.spacing.unit,
    paddingLeft:theme.spacing.unit,
    paddingRight:theme.spacing.unit,
    color: "white",
    backgroundColor: "black",
  },

  black: {
    color: "white",
    backgroundColor: "black",
    border: "1px solid green"
  },
  white: {
    color: "black",
    backgroundColor: "white",
    border: "1px solid green"
  },
  red: {
    color: "black",
    backgroundColor: "red",
    border: "1px solid blue"
  },

  small_button: {
    minHeight:20,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingTop:0,
    paddingBottom:0,
    marginLeft: theme.spacing.unit*2
  }
});


const GET_BOOKING_REF = gql`
mutation GenerateBookingRef {
  generateBookingRef
}`;

const GET_PUBLIC_EVENTS = gql`
query PublicEvents{
  events {
    id
    status
    name
    date
    place {
      id
      name
      long_name
      address
      map
    }
    image {
      id
      url
    }
  }
}
`;

const GET_PUBLIC_EVENT = gql`
query PublicEvent($event_id:ID!,$booking_ref:String!,$with_order:Boolean!,$public_order_key:String!){
  event(id:$event_id,booking_ref:$booking_ref) {
    id
    name
    status
    date
    place {
      id
      name
      long_name
      address
      map
    }
    image {
      id
      url
    }
    zones {
      id
      zone_id
      map_zone_key
      free_zone
      name
      primary_color
      secondary_color
    }
    seats {
      id
      name
      type
      capacity
      event_zone_id
      seat_id
      zone_id
      name
      type
      capacity
      row_label_base
      col_label_base
      box_capacity_left
      box_capacity_right
      box_capacity_top
      box_capacity_bottom
      map_position_x
      map_position_y
      map_position_angle
      map_sector_angle
    }

    activezoneprices {
      id
      name
      cost
      sale_student
      event_price_plan_id
      event_zone_id
      zone_price_id
    }
    
    capacity_info {
      total_capacity
      free_capacity
      zone_infos {
        event_zone_id
        total_capacity
        free_capacity
        markers {
          type
          my_booking
          event_seat_id
          event_zone_id
          event_seat_idx
        }
      } 
    } 

  }
  cartTickets:eventTicketBookings(event_id:$event_id,booking_ref:$booking_ref) {
    status
    ticket_key
    event_id
    event_zone_id
    event_seat_id
    event_seat_idx
    name
    comment
    cost
    sale_student
    booking_ref
    payment_id
    created_at
    updated_at
  }
  order:eventOrder(public_order_key:$public_order_key,booking_ref:$booking_ref) @include(if: $with_order) {
    public_key  
    status
    last_payment_status
    price
    customer_name
    customer_surname
    customer_phone
    customer_email
    tickets:event_tickets {
      status
      ticket_key
      event_id
      event_zone_id
      event_seat_id
      event_seat_idx
      name
      comment
      cost
      sale_student
      booking_ref
      payment_id
      created_at
      updated_at
    }
  }
}
`;


class TicketsApp extends React.Component {

  constructor(props) {
    super(props);
  }

  isValidBookingRef() {
    if (this.props.booking_ref) {
      if (this.props.booking_ref.length>0) {
        return true;
      }
    }
    return false;
  }

  componentDidMount() {
    //fetch booking ref
    console.log("EnrollApp.componentDidMount, current booking_ref",this.props.booking_ref, "date",this.props.last_booking_date);
    if (this.isValidBookingRef()) {
      console.log("booking_ref is valid");
    } else {
      console.log("invalid booking_ref");
      this.props.generateBookingRef({}).then(res=>{
        console.log("new booking ref",res);
        if (res.data && res.data.generateBookingRef) {
          this.props.onSetBookingRef(res.data.generateBookingRef);
        } else {
          console.error("can't get new booking ref, mutation result is empty");
        }
      }).catch(err=>{
        console.error("can't get new booking ref",err);
      });
    }
  }

  onSelectEvent(event) {
    this.props.history.push('/tickets_spa/'+event.id)
  }

  renderBar(title,back_button) {
    const { classes } = this.props;
    return (
      <Grid className={classes.main_bar} container sx={12}  alignItems="baseline"   justify="flex-start">
        <Grid item sx={12} md={10}>
          <Typography variant="title" color="inherit"> {title} </Typography>
        </Grid>
        <Grid item sx={12} md={2}>
          {back_button &&(  
            <Button variant="contained" className={classes.small_button} size="small" component={Link} to={"/tickets_spa/"}> zpět na seznam akcí </Button>
          )}
        </Grid>
      </Grid>
    )
  }

  render() {
    const { classes, booking_ref } = this.props;
    return (
      <React.Fragment>
        <CssBaseline/>
        <Version short/>
        <div className={classes.root}>
        <Grid container> 

          <Grid className={classes.toplinks} container sx={12}  justify="space-around">
            <Grid item sx={12} md={4}>
              <Grid container>
                <Grid item>
                  <MapIcon/>
                </Grid>
                <Grid item>
                  <Typography variant="body2">Špitálka 253/6, 602 00 Brno–Zábrdovice</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={12} md={3}>
              <Grid container>
                <Grid item>
                  <PhoneIcon/>
                </Grid>
                <Grid item>
                  <Typography variant="body2">+420 732 754 293</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={12} md={3}>
              <a style={{color:"unset"}} href={"mailto:starlet@starlet-brno.cz"}>
                <Grid container>
                  <Grid item>
                    <EmailIcon/>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">starlet@starlet-brno.cz</Typography>
                  </Grid>
                </Grid>
              </a>                 
            </Grid>

            <Grid item sx={12} md={2}>
              <a href={"https://www.facebook.com/tsstarlet"}>
                <svg style={{width:24,height:24}} viewBox={"0 0 24 24"}>
                  <path fill={"#000000"} d={"M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z"}/>
                </svg>
              </a>
            </Grid>
         
          </Grid>
          
          <Grid className={classes.topbanner} container sx={12}  alignItems="center" justify="space-around">
            <Grid item sx={12} md={8}>
                <img  src="/images/starlet_akce_logo_g.jpg" width={500} height={100}/>
            </Grid>
            <Grid item sx={12} md={4}>

                <Grid container justify="center" alignItems="center" direction="column"  >
                  <Grid item>
                   <Typography variant="title"> MÁTE DOTAZ? </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <Grid item>
                        <PhoneIcon/>
                      </Grid>
                      <Grid item>
                        <Typography variant="title">+420 732 754 293</Typography>
                      </Grid>
                    </Grid>
                  </Grid>  
                </Grid>
            </Grid>
          </Grid>
          

            <Switch>
                <Route path="/tickets_spa/:event_id/:act?/:act_p?" render={props=>{
                  console.log('render route /tickets_spa/:event_id',props);
                  const event_id = props.match.params.event_id;
                  const x_booking_ref = this.isValidBookingRef()?booking_ref:"missing";
                  const public_order_id = ((props.match.params.act == "order" || props.match.params.act == "order_done") && props.match.params.act_p!=undefined)?props.match.params.act_p:null;
                  const as_order = (props.match.params.act == "order");
                  const as_order_done = (props.match.params.act == "order_done");
                  const with_order = ((public_order_id!=null) && this.isValidBookingRef());
                  //console.log("XXXXX - ",{with_order,as_order_done,as_order});
                  return (
                    <React.Fragment>
                      

                       <Query query={GET_PUBLIC_EVENT} variables={{event_id,booking_ref:x_booking_ref,with_order,public_order_key:public_order_id?public_order_id:"missing"}}  fetchPolicy={"network-only"}>
                          {({ loading, error, data, refetch }) => {
                            if (loading) return (<CircularProgress/>);
                            if (error) return `Error!: ${error}`;
                            const event = data.event;
                            const my_tickets = data.cartTickets;
                            const order = with_order?data.order:null;
                            if (event==null) {
                              return this.renderBar("Akce nenalezena, prodej byl ukončen",true);
                            } 
                            if ((!as_order_done) && order && order.status && order.status!='INCOMPLETE') {
                              console.log("XXXX - force redirect to order_done",{as_order_done,order})
                              return  (<Redirect to={"/tickets_spa/"+event_id+"/order_done/"+order.public_key} />);
                            }
                            return (
                              <React.Fragment>
                                {this.renderBar("Akce "+event.name,true)}
                                <Grid  container sx={12}  style={{minHeight:400, paddingTop:20, paddingBottom:20}} alignItems="baseline"   justify="flex-start">
                                  <Grid  item sx={12} md={12}>
                                    
                                    
                                    {as_order_done?(
                                      <TicketsAppSaleDone event={event} refetch={refetch} public_order_id={public_order_id} my_order={order} booking_ref={x_booking_ref}/>
                                    ):(
                                      <TicketsAppSale event={event} refetch={refetch} as_order={as_order} public_order_id={public_order_id} my_order={order} booking_ref={x_booking_ref} my_tickets={my_tickets}/>
                                    )}
                                  </Grid>
                                </Grid>
                              
                              </React.Fragment>
                            )}}
                      </Query>
                    </React.Fragment>
                  )
                }}/>
                <Route exact path="/tickets_spa/" render={props=>{
                  console.log('render route /tickets_spa',props);
                  return (
                    <React.Fragment>
                      {this.renderBar("Přehled akcí",false)}
                      <Grid  container sx={12}  style={{minHeight:200, paddingTop:20, paddingBottom:20}} alignItems="baseline"   justify="flex-start">
                        <Grid  item sx={12} md={12}>
                          <Query query={GET_PUBLIC_EVENTS} fetchPolicy={"network-only"}>
                              {({ loading, error, data, refetch }) => {
                                if (loading) return (<CircularProgress/>);
                                if (error) return `Error!: ${error}`;

                                const events = data.events;
                                if ((events.length == 0) /*|| (!dev_mode)*/) {
                                  return (
                                    <Typography variant="title" color="inherit"> Aktuálně nejsou v prodeji žádné vstupenky </Typography>
                                  )
                                } else {
                                  return (
                                    <React.Fragment>
                                    {events.map(e=>{
                                      return (
                                        <React.Fragment key={e.id}>
                                          <EventStrip public key={e.id} event={e} onBuy={(event)=>this.onSelectEvent(event)}/>
                                          <Divider />
                                        </React.Fragment>
                                        )
                                    })}
                                    </React.Fragment>
                                    
                                  );
                                }
                              }}
                            </Query>
                          </Grid>
                      </Grid>
                    </React.Fragment>
                  )
                }}/>
            </Switch>

          
         




          <Grid className={classes.bottom_bar} container sx={12} alignItems="baseline"   justify="flex-start">
            <Grid item sx={12} md={12}>
              <Typography className={classes.header_t} color="inherit" variant="body1">Kontaktní telefon: 732 754 293, 545 162 090 a e-mail: starlet@starlet-brno.cz</Typography>
              <Typography className={classes.header_t} color="inherit" variant="body1">Vstupenky, přihlášky a informace: Kancelář TŠ, Špitálka 6 (po-pá 9:00-15:00)</Typography>
              <Typography className={classes.header_t} color="inherit" variant="body1">Copyright © 2019 Taneční škola Starlet Brno, <a style={{color:"white"}} href="https://www.starlet-brno.cz/obchodnipodminky-akce"> obchodní podmínky</a></Typography>
            </Grid>
          </Grid>


        </Grid>
        </div>
      </React.Fragment>
    );
  }
}

TicketsApp.propTypes = {
  classes: PropTypes.object.isRequired,
  booking_ref: PropTypes.string,
  last_booking_date: PropTypes.string,
  generateBookingRef: PropTypes.func.isRequired,
  onSetBookingRef: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  dev_mode: PropTypes.bool
};
TicketsApp.defaultProps = {
  dev_mode:false
};

function mapStateToProps(state) {
  return { 
      booking_ref: state.booking_ref,
      last_booking_date: state.last_booking_date
  }
}
  
const mapDispatchToProps = dispatch => {
  return {
    onSetBookingRef: ref => {
      dispatch(setBookingRef(ref))
    },
  }
}


export default withRouter(compose(
  withStyles(styles),
  graphql(GET_BOOKING_REF,{
    name: "generateBookingRef",
  }),
  connect(mapStateToProps,mapDispatchToProps)
)(TicketsApp));

