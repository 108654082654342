import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { compose, graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LinearProgress from '@material-ui/core/LinearProgress';
import TicketsAppCartDone from './TicketsAppCartDone';
import TicketsAppOrderDone from './TicketsAppOrderDone';

const styles = theme => ({
  grid_root: {
      flexGrow: 1,
     // border: "1px solid green",
  },
  paper: {
    padding: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit,
  },
});



const CHECK_MY_ORDER_PUBLIC = gql`
query eventOrder($booking_ref: String!,$public_order_key: String!) {
  order:eventOrder(public_order_key:$public_order_key,booking_ref:$booking_ref) {
    public_key  
    status
    ticket_pdf_url
    last_payment_status
  }
}
`;

const REBUY_EVENT_TICKET_ORDER_PUBLIC = gql`
mutation eventTicketReBuyOrder($event_id: ID!,$booking_ref: String!,$order_public_key: String!) {
  eventTicketReBuyOrder(event_id:$event_id,booking_ref:$booking_ref,public_order_key:$order_public_key) {
    ok
    error_code
    error_msg
    payment_url
  }
}
`;



class TicketsAppSaleDone extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        pooling:false,
        check_it:0,
        wait:true,
      }
  } 


  componentDidMount() {
    this.timerID = setInterval(
      () => this.tick(),
      3000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    if (this.state.wait) {
      this.setState({check_it:this.state.check_it+1});
    }
    if (this.props.my_order && this.state.wait && !this.state.pooling) {
      console.log("waiting for order ")
      this.setState({pooling:true},()=>{

        this.props.client.query({
          query: CHECK_MY_ORDER_PUBLIC,
          fetchPolicy:"network-only",
          variables: {
            public_order_key: this.props.my_order.public_key,
            booking_ref: this.props.booking_ref
          }
        }).then(res=>{
          this.setState({pooling:false}) 
          //alert("check order res:"+JSON.stringify(res))
          if (res.data && res.data.order) {
            if (res.data.order.last_payment_status != null) {
              this.reload();
            }
          }
        }).catch(err=>{
          alert(err);
          this.setState({pooling:false})
        })
      }) 
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {my_order} = nextProps;
    
    if (my_order && my_order.last_payment_status == null && my_order.status=="PENDING") {
      return {wait:true};
    } else {
      return {wait:false};
    }
  }

  reload() {
    this.props.refetch();
  }

  getTicketsPdfUrl(cb) {

    this.props.client.query({
      query: CHECK_MY_ORDER_PUBLIC,
      fetchPolicy:"network-only",
      variables: {
        public_order_key: this.props.my_order.public_key,
        booking_ref: this.props.booking_ref
      }
    }).then(res=>{
      if (res.data && res.data.order && res.data.order.ticket_pdf_url) {
        cb(res.data.order.ticket_pdf_url)
      } else {
        cb(null);
      }
    }).catch(err=>{
      alert(err);
      this.reload();
    });
   
  }
  handleRePay(cb) {
    

    const {public_order_id,booking_ref,event} =  this.props;
    this.props.reBuyEventTicketOrder({
      variables: {
        event_id:event.id,
        order_public_key: public_order_id,
        booking_ref:booking_ref
      }
    }).then(res=>{
      if (!res.data.eventTicketReBuyOrder.ok) {
        alert(res.data.eventTicketReBuyOrder.error_msg);
        cb(null);
        this.reload();
      } else {
        cb(res.data.eventTicketReBuyOrder.payment_url);
      }
    }).catch(err=>{
      alert(err);
      cb(null);
      this.reload();
      
    })

  }
  
  
  renderWaitDialog() {
    const {check_it} = this.state;
    const {public_order_id} = this.props;
    const hint =  check_it>2;
    const hint2 = check_it>7;
    
    return (
      <Dialog open={true}>
        <DialogTitle>Vyčkejte na potvrzení platby</DialogTitle>
        {hint && (
            <DialogContent>
            <DialogContentText>
              {hint2?(
              <React.Fragment>
                  Tohle už je hodně divné, ale povtrzení platby stále nepřišlo. Poznačte si prosím číslo objednávky {public_order_id} a zkuste stránku znovu načíst. Pokud to nepomůže, tak nás prosím kontaktujte a společně to vyřešíme.
              </React.Fragment>):(
                <React.Fragment>
                    Dnes to trvá déle než je obvyklé. Povtrzení platby objednávky číslo {public_order_id} k nám stále nedorazilo. 
                </React.Fragment>
              )}
            </DialogContentText>
          </DialogContent>
        )}
        <LinearProgress />
      </Dialog>
    )
  }
  
  render() {
    const { classes,event,my_order,public_order_id} = this.props;

    if (my_order==null) {
      return (
        <div className={classes.grid_root}>
          <Typography variant="title" color="inherit" >Objednávka {public_order_id} nebyla nalezena</Typography>
        </div>
      )
    }

    const {wait} = this.state;
    if (wait) {
      return this.renderWaitDialog();
    }
    const paid = my_order.status == "PAID";
    return (
      <React.Fragment>
            <div className={classes.grid_root}>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={8}>
                  <TicketsAppOrderDone event={event} order={my_order} paid={paid} onRePay={(cb)=>this.handleRePay(cb)}get_pdf_url={(cb)=>this.getTicketsPdfUrl(cb)} />
              </Grid>
              <Grid item xs={12} sm={4}>
                 <TicketsAppCartDone event={event} order={my_order} paid={paid} />
              </Grid>
            </Grid>
      
            </div>
      </React.Fragment>
    );
  }
}

TicketsAppSaleDone.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  my_order: PropTypes.object,
  public_order_id: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  booking_ref: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  reBuyEventTicketOrder: PropTypes.func.isRequired,
};
TicketsAppSaleDone.defaultProps = {
 
};



export default withRouter(compose(
  withStyles(styles),
  graphql(REBUY_EVENT_TICKET_ORDER_PUBLIC,{
    name: "reBuyEventTicketOrder",
  }),
  withApollo,
)(TicketsAppSaleDone));
