import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import TicketIcon from '@material-ui/icons/Receipt';
import Avatar from '@material-ui/core/Avatar';
import Lodash from "lodash";
import Toolbar from '@material-ui/core/Toolbar'
import ShopIcon from '@material-ui/icons/ShoppingCart';
import HelpIcon from '@material-ui/icons/HelpOutline';
import Divider from '@material-ui/core/Divider'
import Moment from 'moment';
import DateView from './DateView';
import Tooltip from '@material-ui/core/Tooltip';

import {getTicketPlaceText,getTicketPrimaryText} from "../libs/Tickets";

const styles = theme => ({
  toolbar: {
    //border: "1px solid green",
  },
  root: {
    flexGrow: 1,
    maxWidth: 400,
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    height: 300,
  },
  grow: {
    flexGrow: 1,
  },
  button: {

  },
  res_info: {
    marginLeft: theme.spacing.unit*3,
    //border: "1px solid green",
  },
  shopicon: {
    marginRight: 20
  },
  helpicon: {
    marginLeft: theme.spacing.unit,
  }
});






class SaleEventOrderCartList extends React.Component {


  render() {
    const { classes,list,dense,paid,order_price,event_seats,event_zones } = this.props;
    const last_ticket_m = Lodash.reduce(list,(m,t)=>{
      const um = Moment(t.updated_at).add(1,"h");
      if (m==null) {
        return um;
      } else {
        return um.isAfter(m)?um:m; 
      }
    },null);
   // const empty = list.length==0;
    return (
      <div className={classes.root}>
        <Toolbar variant={"dense"}  className={classes.toolbar}>
          <ShopIcon  className={classes.shopicon} />
          <Typography variant="title" color="inherit"> {paid?"Vaše vstupenky":"Objednané vstupenky"} </Typography>
        </Toolbar>
        <Divider/>
        <div className={classes.list}>
        <List dense={dense}>
          {list.map((t,idx)=>{
            return (
              <ListItem key={idx}>
                <ListItemAvatar>
                  <Avatar>
                    <TicketIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={getTicketPrimaryText(t,event_zones)}
                  secondary={getTicketPlaceText(t,event_seats)}
                />
              </ListItem>
            )
          })}
        </List>
        </div>
        <Divider/>
        {paid?(
          <Typography variant={"body2"} className={classes.res_info}> &nbsp; </Typography>
        ):(
          <Typography variant={"body2"} className={classes.res_info}> 
              vstupenky rezervovány do <DateView date={last_ticket_m}/> 
              <Tooltip title={"pokud nezakoupíte vstupenky do uvedeného času, budou uvolněny dalším zájemcům"}>
                <HelpIcon className={classes.helpicon} fontSize={"inherit"}/>
              </Tooltip>
              
          </Typography>
        )}
        <Divider/>
        <Toolbar variant={"dense"}  className={classes.toolbar}> 
            <Typography className={classes.grow} variant="title" color="inherit"> Celková cena: {order_price}kč </Typography>
         </Toolbar>
      </div>
    );
  }
}

SaleEventOrderCartList.propTypes = {
  classes: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  event_seats: PropTypes.array.isRequired,
  event_zones: PropTypes.array.isRequired,
  dense: PropTypes.bool,
  paid: PropTypes.bool.isRequired,
  order_price: PropTypes.number.isRequired,
};
SaleEventOrderCartList.defaultProps = {
  dense:false,
};


export default withStyles(styles)(SaleEventOrderCartList);