import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { compose } from 'react-apollo';
import StringViewField from './StringViewField';
//import Lodash from 'lodash';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import AccountIcon from '@material-ui/icons/AccountBox';


const styles = theme => ({
  grid_root: {
      flexGrow: 1,
  },
  toolbar: {
    //border: "1px solid green",
  },
  paper: {
    padding: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit,
  },
  accounticon: {
    marginRight: 20
  },
  grow: {
    flexGrow: 1,
  },
  desc: {
    padding: theme.spacing.unit
  },
  button: {
    margin: theme.spacing.unit
  }
});


class SaleEventOrderDone extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        wait:false
      }
  } 

  handleDownloadTickets(){
    this.props.get_pdf_url((url)=>{
      if (url) {
        window.location.href=url+"?download";
      } else {
        alert('Vstupenky se právě připravují, zkuste to za chvilku.');
      }
    })

  }
  handleRePay() {
    this.setState({wait:true})
    this.props.onRePay((url)=>{
      if (url) {
        window.location.href=url;
      } else {
        alert("Jejda, něco se pokazilo")
        this.setState({wait:false});
      }
    })
  }

  render() {
    const { classes,order,paid} = this.props;
    const {wait} = this.state;
    return (
      <React.Fragment>
        <Toolbar variant={"dense"}  className={classes.toolbar}>
          <AccountIcon  className={classes.accounticon} />
          <Typography className={classes.grow} variant="title" color={paid?"inherit":"error"}> 
            {paid?(
              "Vaše objednávka "+order.public_key+" je uhrazena"
            ):(
              "Vaše objednávka "+order.public_key+" nebyla uhrazena"
            )}
          </Typography>
        </Toolbar>
        <Divider/>


        <Typography className={classes.desc} variant="subheading" color="inherit">
            {paid?(
              "Vstupenky jsme odeslali na uvedenou emailovou adresu a kódy vstupenek zasíláme pro jistotu i jako SMS. Vstupenky si taktéž můžete rovnou stáhnout a vytisknout. Pokud nemůžete vstupenky najít ve Vaší poště, zkuste se podívat do složky SPAM nebo do hromadné pošty. V připadě, že se k vám vstupenky nedostanou, prosím kontaktujte nás. Vstup na akci bude umožněn pouze tomu, kdo se jako první prokáže unikátním kódem na vstupence. Opakovaný vstup není možný, proto chraňte vstupenku před jejím zneužitím."
              ):(
              "Vstupenky po zaplacení zašleme na uvedenou emailovou adresu a kódy vstupenek pro jistotu i jako SMS."
            )} 
        </Typography>

        <StringViewField required width={300} id="name" label="Jméno" value={order.customer_name} />
        <StringViewField required width={300} id="surname" label="Přijmení" value={order.customer_surname}/>
        <StringViewField required width={300} id="phone" label="Telefon" value={order.customer_phone}  />
        <StringViewField required width={300} id="email" label="Email" value={order.customer_email}  />

      
        <Toolbar variant={"dense"}  className={classes.toolbar}>
          {paid?(
            <React.Fragment>
              <Typography className={classes.grow} variant="body2" color="inherit"> Vstupenky si můžete uložit do počítače a vytisknout.  </Typography>
              <Button className={classes.button} variant="contained"  onClick={()=>this.handleDownloadTickets()}> Uložit  </Button>
            </React.Fragment>
          ):(
            <React.Fragment>
              <Typography className={classes.grow} variant="title" color="inherit"> Platba se nezdařila, opakujte prosím platbu. </Typography>
              <Button className={classes.button} disabled={wait} variant="contained" onClick={()=>this.handleRePay()}> zaplatit </Button>
            </React.Fragment>
          )}
        </Toolbar>

      </React.Fragment>
    );
  }
}

SaleEventOrderDone.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  paid: PropTypes.bool.isRequired,
  get_pdf_url: PropTypes.func.isRequired,
  onRePay: PropTypes.func.isRequired,
};
SaleEventOrderDone.defaultProps = {
 
};



export default compose(
  withStyles(styles),
)(SaleEventOrderDone)
