import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SaleEventChooseTicket from './SaleEventChooseTicket';
import Paper from '@material-ui/core/Paper';



const styles = theme => ({
  root: {
  //   border: "1px solid red",
     flexGrow: 1,
  },
});





class TicketsAppChoose extends React.Component {

  constructor(props) {
    super(props);
  }
 
  render() {
    const { classes,event, onCreateNewTicket } = this.props;

    return (
      <Paper className={classes.root}>
        <SaleEventChooseTicket event={event} onCreateNewTicket={onCreateNewTicket}/>
      </Paper>
    );
  }
}

TicketsAppChoose.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  onCreateNewTicket: PropTypes.func.isRequired,
};
TicketsAppChoose.defaultProps = {
  
};


export default withStyles(styles)(TicketsAppChoose);