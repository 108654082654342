import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SaleEventBuyTicket from './SaleEventBuyTicket';
import Paper from '@material-ui/core/Paper';



const styles = theme => ({
  root: {
  //   border: "1px solid red",
     flexGrow: 1,
  },
});





class TicketsAppOrder extends React.Component {

  constructor(props) {
    super(props);
  }
 
  render() {
    const { classes,event, public_order_id, order, refetch, onCreateOrder , onBack, onBuy} = this.props;

    return (
      <Paper className={classes.root}>
        <SaleEventBuyTicket event={event} onBack={onBack} onBuy={onBuy} onCreateOrder={onCreateOrder} refetch={refetch} public_order_id={public_order_id} order={order}/>
      </Paper>
    );
  }
}

TicketsAppOrder.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  public_order_id: PropTypes.string,
  order: PropTypes.object,
  onCreateOrder: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onBuy: PropTypes.func.isRequired,
};
TicketsAppOrder.defaultProps = {
  
};


export default withStyles(styles)(TicketsAppOrder);