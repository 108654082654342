import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SaleEventShoppingCartList from './SaleEventShoppingCartList';
import Paper from '@material-ui/core/Paper';



const styles = theme => ({
  root: {
   //  border: "1px solid red",
     flexGrow: 1,
  },
});





class TicketsAppCart extends React.Component {

  constructor(props) {
    super(props);
    this.list = React.createRef();
  }
 
  render() {
    const { classes,event,my_tickets, as_order,has_order } = this.props;

    return (
      <Paper className={classes.root}>
        <SaleEventShoppingCartList as_order={as_order} has_order={has_order} innerRef={this.list}  onDelete={this.props.onDelete} onBuy={this.props.onBuy} onModify={this.props.onModify} list={my_tickets} event_seats={event.seats} event_zones={event.zones}/>  
      </Paper>
    );
  }
}

TicketsAppCart.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onBuy: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
  my_tickets: PropTypes.array.isRequired,
  as_order: PropTypes.bool,
  has_order: PropTypes.bool
};
TicketsAppCart.defaultProps = {
  as_order:false,
  has_order:false,
};


export default withStyles(styles)(TicketsAppCart);